import { Database } from '@/database.types';
import { createBrowserClient } from '@supabase/ssr';
// @ts-ignore

let debug = false;
if (typeof window !== 'undefined') {
    debug = localStorage.getItem('debug')?.includes('auth') || false;
}
export function createClient() {
    return createBrowserClient<Database>(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
        {
            auth: {
                debug: false,
            },
        }
    );
}
