import { webviewLogger } from '@/debug/webview';
import { darkModeState } from '@/jotai';
import { createClient } from '@/supabase/utils/client';
import { useAtom } from 'jotai';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
export function WebViewCommunicator() {
  const [darkMode] = useAtom(darkModeState);
  const pathname = usePathname();
  const [user, setUser] = useState<{
    status: number;
    user: null | object;
  }>({
    status: SESSION_STATE.LOADING,
    user: null
  });
  useEffect(() => {
    sendWebViewData();
    function sendWebViewData() {
      document.documentElement.className = darkMode ? 'dark' : 'light';
      // iOS , Aodn 메시지를 준다.  로 메지를 준다.
      const data = {
        darkMode: darkMode ? 'dark' : 'light'
      };
      communicateWithAppsWithCallback('themeChanged', data);
    }
  }, [darkMode]);
  useEffect(() => {
    const supabase = createClient();
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'INITIAL_SESSION') {
        if (session === null || session.user === null) {
          setUser({
            status: SESSION_STATE.UNAUTHENTICATED,
            user: null
          });
        } else {
          setUser({
            status: SESSION_STATE.AUTHENTICATED,
            user: session.user
          });
        }
      }
    });
  }, []);
  function sendWebViewStatus(pathname: string, user: {
    status: number;
    user: null | object;
  }) {
    // iOS , Aodn 메시지를 준다.  로 메지를 준다.
    const data = {
      pathname: pathname,
      user: user
    };
    // @ts-ignore
    window.webkit?.messageHandlers?.statusChanged?.postMessage(data);
  }
  useEffect(() => {
    sendWebViewStatus(pathname, user);
  }, [pathname, user]);
  return null;
}
export function communicateWithAppsWithCallback(funcName: string, data?: any, callback?: Function) {
  webviewLogger('communicateWithAppsWithCallback', {
    funcName,
    data
  });
  // @ts-ignore
  window.webkit?.messageHandlers?.[funcName]?.postMessage(data, callback);
  if (data === undefined) {
    // @ts-ignore
    window.webview?.[funcName]();
  } else {
    // @ts-ignore
    // object는 바로 보낼 수 없기 때문에 직렬화.
    window.webview?.[funcName](typeof data === 'object' ? JSON.stringify(data) : data);
  }
}
const SESSION_STATE = {
  LOADING: 0,
  UNAUTHENTICATED: 1,
  AUTHENTICATED: 2,
  WAITING: 3
};